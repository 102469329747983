import React from "react"
import { navigate, PageProps } from "gatsby"
import { Box, Flex, Icon, usePrevious } from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"
import GatsbyImage, { FluidObject } from "gatsby-image"
import { Value as LayoutFieldValue } from "contentful-layout-field"
import { StringParam, useQueryParam } from "use-query-params"

import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import { Button, ButtonProps } from "../components/Buttons"
import CustomizerOptions from "../components/SideBar/CustomizerOptions"
import Heading from "../components/typography/Heading"
import SmallCaps from "../components/typography/SmallCaps"
import MobileHeader from "../components/SideBar/MobileHeader"
import LayoutBuilder from "../components/LayoutBuilder"
import SEO from "../components/boilerplate/seo"
import {stringToBase64} from "../utils/text"

import { BaseDispatch, BaseRootState } from "../redux/store"
import { createContentfulImageMap, placeholderImage } from "../utils"
import { bp, LaptopDownOnly } from "../utils/MediaQueries"
import { ContentfulProduct, CustomizerPageQuery } from "../../graphql-types"
import { groupCustomizedLineItems } from "../components/Cart/utils"
import { getNormalizedCustomAttributes } from "../redux/models/checkout"
import {
  transformAccessoriesFromCartItems,
  getCustomizerColor,
} from "../utils/customizer"
import { CustomizerBikeType } from "../redux/types/BikeTypes"
import FormattedTitle from "../components/FormattedTitle"
import Header from "../components/BikeProductPage/Header"

type LocationState = {
  previousPath?: string
  fromQuizResults?: {
    speed: string
    color: string
  }
}

type PageContext = {
  bikeSlug: string
  layoutData: LayoutFieldValue
}

type CustomizerProps = PageProps<
  CustomizerPageQuery,
  PageContext,
  LocationState
>

const ExitButton = (props: Omit<ButtonProps, "children">) => (
  <Button
    theme="tertiary"
    color="dawn"
    p="1.0938rem 1.3125rem 1.0938rem 1rem"
    {...props}
  >
    <Icon name="chevron" size="1.25rem" mr="0.875rem" />
    <SmallCaps size="lg" fontWeight="medium">
      Exit
    </SmallCaps>
  </Button>
)

const ChevronButton = (props: Omit<ButtonProps, "children">) => (
  <Button
    theme="tertiary"
    color="dawn"
    pl={["1.2188rem", null, null, null, "1rem"]}
    pr={["1.1287rem", null, null, null, "1rem"]}
    mr="0.8531rem"
    {...props}
  >
    <Icon name="chevron" size="1.25rem" />
  </Button>
)

const Customizer = ({
  data: { bike, images },
  pageContext,
  location,
}: CustomizerProps) => {
  const dispatch = useDispatch<BaseDispatch>()
  const {
    bikes: { customizerBike },
    checkout,
    sidebar: { isOpen: isSideBarOpen },
  } = useSelector((state: BaseRootState) => state)

  let imageMap: any = undefined
  if (images !== undefined) {
    imageMap = createContentfulImageMap(images)
  }

  const pageSlug = pageContext.bikeSlug
  const fromQuizResults = location.state?.fromQuizResults
  const previousFromQuizResults = usePrevious(fromQuizResults)

  // Editing cart item
  const [editCartItemId, setEditCartItemId] = useQueryParam("edit", StringParam)
  const editingCartItem = React.useMemo(
    () =>
      editCartItemId
        ? groupCustomizedLineItems(checkout.data?.lineItems?.edges ?? checkout.data?.lines?.edges ?? []).find(
            (item) => stringToBase64(item.id) === editCartItemId
          )
        : null,
    [editCartItemId, checkout.data]
  )

  // Ensure sidebar state is synced
  React.useEffect(() => {
    // dispatch.quiz.setProgressState("finished")
    dispatch.sidebar.setIsOpen(true)
  }, [])

  // Ensure customizerBike state is initialized
  React.useEffect(() => {
    if (
      !editCartItemId &&
      (!customizerBike ||
        (customizerBike && customizerBike.bike.internalTitle !== pageSlug) ||
        previousFromQuizResults?.color !== fromQuizResults?.color ||
        previousFromQuizResults?.speed !== previousFromQuizResults?.speed)
    ) {
      if (fromQuizResults) {
        const newSpeed = bike?.speeds?.find(
          (speed) => speed?.speed === fromQuizResults.speed
        )
        const newVariant = newSpeed?.variants?.find(
          (variant) => variant?.color?.name === fromQuizResults.color
        )
        const newCustomizerBike = {
          bike,
          speed: newSpeed,
          variant: newVariant,
          warranty: false,
        } as CustomizerBikeType
        dispatch.bikes.setSelectedBike(newCustomizerBike)
        dispatch.bikes.setInitialCustomizerBike(newCustomizerBike)
      } else {
        // Initialize/reset state
        dispatch.bikes.setInitialCustomizerBike({
          bike,
          warranty: false,
        })
      }
    }
  }, [
    customizerBike,
    pageSlug,
    editCartItemId,
    fromQuizResults,
    previousFromQuizResults,
  ])

  // Ensure customizerBike state is initialized when editing an cart item
  React.useEffect(() => {
    if (editingCartItem) {
      const attributes = getNormalizedCustomAttributes(
        editingCartItem?.attributes || []
      )
      // Setup editing a customized cart item
      const contentfulProductId = attributes["contentfulProductId"]
      const contentfulVariantId = attributes["contentfulVariantId"]
      const speed = bike?.speeds?.find(
        (speed) => speed?.contentful_id === contentfulProductId
      )
      const variant = speed?.variants?.find(
        (variant) => variant?.contentful_id === contentfulVariantId
      )
      dispatch.bikes.setCustomizerBike({
        bike,
        speed,
        variant,
        accessories: speed
          ? transformAccessoriesFromCartItems(
              editingCartItem,
              bike,
              speed as Partial<ContentfulProduct>
            )
          : {},
        warranty: !!editingCartItem.warranty,
      })
    } else if (editCartItemId) {
      // Cart item doesn't exist anymore
      setEditCartItemId(undefined, "replace")
    }
  }, [editCartItemId])

  if (!customizerBike) return <Box />

  const currentSpeed =
    bike?.speeds?.find(
      (product) =>
        product?.speed === customizerBike.speed?.speed &&
        product?.variants?.find(
          (variant) =>
            variant?.contentful_id === customizerBike.variant?.contentful_id
        )
    ) ||
    bike?.speeds?.find(
      (product) => product?.speed === customizerBike.speed?.speed
    )

  const accessoryImages = Object.entries(customizerBike.accessories || {})
    .map(([category, variant]) => {
      const sharedCategoryData = customizerBike?.bike?.sharedCustomizerOptions?.accessoryCategories?.find(
        (cat) => cat?.name === category
      )
      const speedCategoryData = currentSpeed?.customizerOptions?.accessoryCategories?.find(
        (cat) => cat?.name === category
      )
      // Prioritize customizer config from speed config over shared config
      const accessories = [
        ...(speedCategoryData?.accessories || []),
        ...(sharedCategoryData?.accessories || []),
      ]
      const imageLayer =
        (speedCategoryData || sharedCategoryData)?.imageLayer || "1"
      const zIndex = Number(imageLayer)
      const asset = accessories?.find(
        (acc) => acc?.variantId === variant.contentful_id
      )?.asset
      return asset
        ? {
            id: asset.contentful_id,
            fluid: asset.fluid,
            zIndex: isNaN(zIndex) ? 1 : zIndex,
          }
        : null
    })
    .filter((x) => !!x) as { id: string; fluid: FluidObject; zIndex: number }[]

  const previousPath = location?.state?.previousPath
    ? location.state.previousPath
    : "/"

  const handleGoBack = () => {
    if (window.history.length > 0) {
      window.history.back()
    } else {
      navigate(previousPath)
    }
  }

  const handleExit = () => {
    if (fromQuizResults) {
      navigate(previousPath)
    } else {
      navigate(`/products/${bike?.internalTitle}`)
    }
  }

  const customizerColor = getCustomizerColor(customizerBike)
  const image = customizerColor?.asset?.fluid
  const fallbackImage =
    customizerBike.variant?.imageLayout?.layout?.[0]?.image?.asset?.fluid ||
    customizerBike.variant?.productListingImage?.fluid ||
    placeholderImage

  // Section Headers
  const sectionHeaders =
    pageContext?.layoutData?.layout
      .filter(
        (data) =>
          data.layoutId === "top-subtitle-heading" && data.headingLabel !== ""
      )
      .map((data) =>
        data.layoutId === "top-subtitle-heading" ? data.headingLabel : ""
      ) || []

  return (
    <Box>
      <SEO
        title={ bike?.customizerPageSeoTitle || "Customizer" }
        description= {bike?.customizerPageSeoDescription?.customizerPageSeoDescription || ""}
        location={location} />
      {fromQuizResults && (
        <LaptopDownOnly>
          <MobileHeader state="customize" onExit={handleExit} />
        </LaptopDownOnly>
      )}
      <Header labels={["customize", ...sectionHeaders]} />
      <Box pos="relative" mt={bp("1rem", "4rem")} pb="1.2138rem">
        <Flex
          pos={bp("static", "absolute")}
          top="1.2138rem"
          left={0}
          zIndex={1}
          w="100%"
          px="1.25rem"
        >
          {fromQuizResults && (
            <Flex w="100%" justify="space-between">
              <Flex align="center">
                <ChevronButton onClick={handleGoBack} />
                <Heading size="4">
                  <FormattedTitle raw={bike?.formattedTitle || ""} />
                </Heading>
              </Flex>
              <ExitButton d={bp("none", "flex")} onClick={handleExit} />
            </Flex>
          )}
          {!fromQuizResults && (
            <Flex align="center">
              <ExitButton onClick={handleExit} mr="1.25rem" />
              {/* <Flex
                as="span"
                display={bp("none", "inline-flex")}
                align="center"
              >
                <Icon
                  name="exclamationCircle"
                  color="dawn"
                  size="1.25rem"
                  mr="0.3125rem"
                />
                <Body size="xs" color="dawn">
                  Your changes won’t be saved if you exit the customizer
                </Body>
              </Flex> */}
            </Flex>
          )}
        </Flex>
        <MaxWidthGrid mb="2.5rem">
          <Flex
            id="customize"
            position="relative"
            gridColumn={["1/13", null, null, null, "4/12"]}
            h={bp("auto", "95vh")}
            mt={bp("1rem", "0")}
            mb={bp("3rem", "1rem")}
            align="center"
            justify="center"
          >
            <Box
              pos="relative"
              w={["100%", null, null, null, null, null, "80%"]}
              pb={bp(!image ? "3rem" : "0", "0")}
            >
              {image &&
                accessoryImages.map(({ id, fluid, zIndex }) => (
                  <Box
                    key={id}
                    pos="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    zIndex={1 + zIndex}
                  >
                    <GatsbyImage fluid={fluid} />
                  </Box>
                ))}
              <GatsbyImage fluid={(image || fallbackImage) as FluidObject} />
            </Box>
          </Flex>
          <CustomizerOptions
            className="CustomizerOptions"
            display={["block", null, null, null, "none"]}
            gridColumn="1/13"
            h="auto"
          />
        </MaxWidthGrid>
        <LayoutBuilder
          data={pageContext.layoutData}
          imageMap={imageMap}
          variant={currentSpeed?.speed ?? undefined}
        />
      </Box>
    </Box>
  )
}

export default Customizer
